.wimmelbild .image-mapper a {
    cursor: pointer;
}
.wimmelbild .image-mapper rect, .wimmelbild .image-mapper circle, .wimmelbild .image-mapper polygon {
    fill: #fff;
    opacity: 0;
}
.wimmelbild .image-mapper rect:hover, .wimmelbild .image-mapper circle:hover, .wimmelbild .image-mapper polygon:hover {
    fill: rgb(241, 171, 171);
    opacity: 0.5;
}
.msg_success_wimmelbild {
    width: 50%;
    height: 30%;
    background-color: white;
    position: absolute;
    top: 35%;
    left: 25%;
    z-index: 300;
    border-radius: 5vh;
    border: solid 3px black;
    align-items: center;
    visibility: hidden;
}
.msg_success_wimmelbild p {
    margin-top: 20%;
}