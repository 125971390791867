/* just-another-hand-regular - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Just Another Hand';
    font-style: normal;
    font-weight: 400;
    src: url('../../assets/fonts/just-another-hand-v19-latin-regular.eot'); /* IE9 Compat Modes */
    src: url('../../assets/fonts/just-another-hand-v19-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../assets/fonts/just-another-hand-v19-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../assets/fonts/just-another-hand-v19-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../../assets/fonts/just-another-hand-v19-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../assets/fonts/just-another-hand-v19-latin-regular.svg#JustAnotherHand') format('svg'); /* Legacy iOS */
  }

.infopage_wrapper {
    padding-top: 15vh;
    height: 85vh;
    width: 100vw;
    background-image: url('../../assets/backgrounds/spacebg.jpg');
    background-size: cover;
    font-size: calc(12px + 1vh);
}

.info-page {
    width: 60vw;
    height: 70vh;
    margin: 0 auto;
    background-color: rgb(0, 0, 0, 0.5);
    border-radius: 5vh 0vh 0vh 5vh;
    overflow-y: scroll;
    color: white;
    padding: 0vh 1vw 0vh 1vw;
}

.info-page>p {
    text-align: left;
}

a {
    color: #ffb11f;
}

#infologo {
    width: 30vw;
    padding-top: 5vh;
}

.info-section {
    margin-top: 2em;
    font-family: 'Just Another Hand';
    text-transform: uppercase;
    letter-spacing: 0.1em;
    color: #ffb11f;
    font-size: calc(18px + 1vh);
}

.info-subsection {
    margin-top: 1.5em;
    font-family: 'Just Another Hand';
    text-transform: uppercase;
    letter-spacing: 0.1em;
    color: #ffb11f;
}

.info-subsection+p {}

li {
    text-align: left;

}

/*.info-bg {
    position: fixed;
    bottom: 0px;
    width: 100%;
    height: 10vh;
    background-image: url('../../assets/backgrounds/bg2.svg');
}*/