/* just-another-hand-regular - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Just Another Hand';
    font-style: normal;
    font-weight: 400;
    src: url('../../assets/fonts/just-another-hand-v19-latin-regular.eot'); /* IE9 Compat Modes */
    src: url('../../assets/fonts/just-another-hand-v19-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../assets/fonts/just-another-hand-v19-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../assets/fonts/just-another-hand-v19-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../../assets/fonts/just-another-hand-v19-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../assets/fonts/just-another-hand-v19-latin-regular.svg#JustAnotherHand') format('svg'); /* Legacy iOS */
  }

.hilfe_wrapper {
    padding-top: 15vh;
    height: 85vh;
    width: 100vw;
    background-image: url('../../assets/backgrounds/spacebg.jpg');
    background-size: cover;
}

.hilfe_content {
    padding: 1vh 3vw 5vh 3vw;
    color: white;
    width: 60vw;
    margin: 0 auto;
    background-color: rgb(0, 0, 0, 0.5);
    border-radius: 5vh 5vh 5vh 5vh;
    height: 70vh;
}

#hilfe-container {
    height: 45vh;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

#hilfe-telefon {
    margin-left: 10%;
    margin-right: 10%;
}

#hilfe-homepage {
    margin-left: 10%;
    margin-right: 10%;
}

#hilfe-container img {
    width: 20vh;
    height: 20vh;
    border-radius: 10%;
    border: 2px solid #454545;
}