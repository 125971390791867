.jigsaw-puzzle {
    width: 60%;
    max-height: 55vh;
    margin: 0 auto;
}
.puzzle {
    width: 100%;
    display: grid;
    justify-content: center;
    align-items: center;
}
.msg_success {
    width: 50%;
    height: 30%;
    background-color: white;
    position: absolute;
    top: 35%;
    left: 25%;
    z-index: 200;
    border-radius: 5vh;
    border: solid 3px black;
    align-items: center;
    visibility: hidden;
}
.msg_success p {
    margin-top: 20%;
}