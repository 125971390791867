.external-video {
    max-width: 100%;
    height: 70vh;
}
.external-video iframe {
    border:0px;
}
.PDF {
    max-width: 100%;
    height: 80vh;
}
.PDF iframe {
    border: 0px;
}