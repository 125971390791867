.card-grid {
  margin-top: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
}
.msg_success_memory {
  width: 50%;
  height: 30%;
  background-color: white;
  position: absolute;
  top: 35%;
  left: 25%;
  z-index: 200;
  border-radius: 5vh;
  border: solid 3px black;
  align-items: center;
  visibility: hidden;
}
.msg_success_memory p {
  margin-top: 20%;
}