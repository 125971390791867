.qr_reader_wrapper {
    padding-top: 15vh;
    height: 85vh;
    width: 100vw;
    background-image: url('../../assets/backgrounds/spacebg.jpg');
    background-size:cover;
}
.qr_reader_wrapper h2 {
    margin-top: -5vh;
}
.qr_reader_content {
    height: 60vh;
    width: 50vw;
    background-color: rgb(0,0,0,0.5);
    border-radius: 3vh;
    overflow-y: auto;
    margin: 0 auto;
    color: white;
    display: grid;
    grid-template-columns: 50% 50%;
}
.qr_reader_text {
    text-align: left;
}
.avatar_qr {
    height: 40vh;
}
.avatar_qr img {
    height: 50vh;
    position: relative;
}

.avatar_0 {
    position: absolute;
    left: 30vw;
    z-index: 3;
}
.avatar_1 {
    position: absolute;
    left: 30vw;
    z-index: 4;
}
.avatar_2 {
    position: absolute;
    left: 30vw;
    z-index: 5;
}
.avatar_3 {
    position: absolute;
    left: 30vw;
    z-index: 6;
}
.avatar_4 {
    position: absolute;
    left: 30vw;
    z-index: 2;
}
.avatar_5 {
    position: absolute;
    left: 30vw;
    z-index: 1;
}
.avatar_6 {
    position: absolute;
    left: 30vw;
    z-index: 7;
}
.avatar_7 {
    position: absolute;
    left: 30vw;
    z-index: 8;
}
.start_app {
    visibility: hidden;
}