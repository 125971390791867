.beteiligung_krise_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.beteiligung_krise_content {
    /*margin-top: 10vh;*/
    width: 80vw;
    /*background-color: white;*/
}
.correct {
    background-color: rgb(3, 238, 3, 0.15);
}
.msg_success_gap {
    width: 50%;
    height: 30%;
    background-color: white;
    position: absolute;
    top: 35%;
    left: 25%;
    z-index: 200;
    border-radius: 5vh;
    border: solid 3px black;
    align-items: center;
    visibility: hidden;
}
.msg_success_gap p {
    margin-top: 20%;
}