/* just-another-hand-regular - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Just Another Hand';
    font-style: normal;
    font-weight: 400;
    src: url('../../assets/fonts/just-another-hand-v19-latin-regular.eot'); /* IE9 Compat Modes */
    src: url('../../assets/fonts/just-another-hand-v19-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../assets/fonts/just-another-hand-v19-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../assets/fonts/just-another-hand-v19-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../../assets/fonts/just-another-hand-v19-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../assets/fonts/just-another-hand-v19-latin-regular.svg#JustAnotherHand') format('svg'); /* Legacy iOS */
  }

h2 {
    margin-top: 2em;
    font-family: 'Just Another Hand';
    text-transform: uppercase;
    letter-spacing: 0.1em;
    color: #ffb11f;
    font-size: calc(18px + 1vh);
}

h3 {
    color: #444444;
    font-size: calc(14px + 1vh);
}