.question-wrapper-invisible {
    display: none;
}

.question-wrapper-visible {
    display: contents;
}

.quiz-title {
    width: 100%;
}

.quiz-content {
    width: 100%;
    /*border: 1px solid red;*/
}

.question-content {
    width: 50%;
    margin: 0 auto;
    /*border: 1px solid green;*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.question-picture img {
    max-width: 50vw;
}

.answer {
    width: 80%;
    margin: 1vh;
    background-color: #777777;
    border: 0.5vh solid #777777;
    border-radius: 1vh;
    cursor: pointer;
    padding: 2vh;
    color: white;
}

.answer_active {
    width: 80%;
    margin: 1vh;
    background-color: orange;
    border: 0.5vh solid #777777;
    border-radius: 1vh;
    cursor: pointer;
    padding: 2vh;
    color: white;
}

.finish-quiz {
    margin-top: 3vh;
    margin-bottom: 3vh;
}

.answer_finish_correct {
    border: 0.5vh solid #009607;
}

.quiz_nav {
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
}

.quiz_back {
    position: absolute;
    left: 10%;
}

.quiz_forward {
    position: absolute;
    right: 10%;
}