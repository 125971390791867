.avatar-container {
    width: 100vw;
    height: 100vh;
    position: absolute;
    bottom: 0;
    /*background-color: orange;*/
    background-image: url('../../assets/backgrounds/bg.svg');
    background-repeat: no-repeat;
    overflow-y: hidden;
    background-position: bottom;
    background-size: 100% auto;
}

.avatar-config-name {
    position: absolute;
    bottom: 10vh;
    width: 50%;
    /*border: 1px solid blue;*/
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    z-index: 200;
}

.avatar-name {
    font-family: 'Silkscreen';
    font-weight: bold;
    padding: 1vh;
    color: white;
    /*background-image: linear-gradient(to bottom, #fbb03b, #e1850c);*/
    background-color: #00000044;
    border-radius: 1vh;
    /*border: 2px solid #454545;*/
    font-size: 1.5vw;
    text-align: center;
    outline: none;
}

.avatar-name:placeholder-shown {
    /*border-color: red;*/
}

.avatar_config_bubbles {
    width: 100%;
    position: relative;
    height: 50vh;
    /*border: 1px solid blue;*/
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 25vh;
    /*border: 2px solid green;*/
}

.avatar_bubbles_left {
    width: 20%;
    height: 100%;
    /*border: 1px solid orange;*/
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-end;
}

.avatar_bubbles_right {
    width: 20%;
    height: 100%;
    /*border: 1px solid orange;*/
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
}

.avatar_advanced_bubbles {
    /*border: 1px solid green;*/
    width: 40%;
    height: 100%;
    /*position: relative;*/
    display: flex;
    justify-content: center;
    align-items: center;
}

.avatar_advanced_bubbles img {
    height: 50vh;
    /*height: 50vh;
    transform: translate(10%);*/
}

.avatar_bubbles {
    height: 100%;
    justify-content: center;
    /*margin: 0 auto;
    position: absolute;
    left: 50%;
    transform: translate(-50%);*/
    z-index: 10;
}

.skin_bubbles {
    top: 5vh;
    position: absolute;
    z-index: 1;
}

.shoe_bubbles {
    top: 5vh;
    position: absolute;
    z-index: 2;
}

.face_bubbles {
    top: 5vh;
    position: absolute;
    z-index: 3;
}

.clothes_bottom_bubbles {
    top: 5vh;
    position: absolute;
    z-index: 4;
}

.clothes_top_bubbles {
    top: 5vh;
    position: absolute;
    z-index: 5;
}

.hair_bubbles {
    top: 5vh;
    position: absolute;
    z-index: 7;
}

.avatar_badge {
    top: 5vh;
    position: absolute;
    z-index: 8
}

.accessoire_bubbles {
    top: 5vh;
    position: absolute;
    z-index: 9
}

.surprise_bubbles {
    top: 5vh;
    position: absolute;
    z-index: 10
}

.avatar_bubbles_button {
    position: relative;
    width: 100%;
    margin-top: 3vh;
}

.avatar_bubbles {
    position: absolute;
    z-index: 10;
    /*border: 1px solid red;*/
    width: 100%;
    height: 100%;
    margin: auto auto;
}

.avatarbtn {
    width: 25%;
    height: 20%;
    /*border: 1px dashed green;*/
    transition: all 0.1s ease-out;
    cursor: pointer;

}

.avatarbtn.active:hover {
    /*filter: drop-shadow(0px 1px 4px #555555);*/
    transform: scale(1.2);
}

.avatarbtn.locked:hover {
    /* Start the shake animation and make the animation last for 0.5 seconds */
    animation: shake 0.5s;

    /* When the animation is finished, start again */
    animation-iteration-count: 1;
}

.shakeme {
    /* Start the shake animation and make the animation last for 0.5 seconds */
    animation: shake 0.5s;

    /* When the animation is finished, start again */
    animation-iteration-count: 1;
}

@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }

    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }

    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }

    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}

.selection_bubble {
    position: absolute;
    z-index: 200;
    display: none;
    background-image: linear-gradient(to bottom, #fbb03b, #e1850c);
    border-radius: 10px;
    border: 2px solid #454545;
    max-height: 50vh;
    overflow-y: auto;
}

.contentItem {
    margin-right: 1.5em;
    min-height: 60px;
    height: 8vh;
}

.contentItem:hover {
    /*filter: drop-shadow(2px 2px 0 #ffffff);
    -webkit-filter: drop-shadow(-2px -2px 0 #ffffff); */
    background: #f7ce93;
    border-radius: 50%;
}