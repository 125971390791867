.homescreenbg {
    width: 100%;
    height: 100vh;
    background-image: url('../../assets/backgrounds/spacebg.jpg');
    background-size: cover;
}

.homescreen-main {
    position: absolute;
    bottom: 1vh;
}
#avatar_big img {
    height: 25vh;
}

#avatar_big div {
    transform: translate(-50%);
}

.medal {
    height: 15vh;
}

.avatar_wrapper {
    position: relative;
    height: 25vh;
    width: 100%;
}

.avatar_big_left {
    height: 25vh;
    position: absolute;
    z-index: 1;
    left: 16.66%;
    top: 0;
    transition: all 1s ease;
}

.avatar_big_center {
    height: 25vh;
    position: absolute;
    z-index: 1;
    left: 50%;
    transform: translate(-50%, 0);
    top: 0;
    transition: all 1s ease;
}

.avatar_big_right {
    height: 25vh;
    position: absolute;
    z-index: 1;
    left: 83.32%;
    top: 0;
    transition: all 1s ease;
}

.nav_welten {
    width: 100%;
    display: inline-block;
    margin-top: -30px;
}

.nav_welten .welten_wrapper {
    float: left;
    width: 33.33%;
    display: flex;
    justify-content: center;
}

.nav_welten img {
    width: 100%;
    display: block;
    margin: auto;
    filter: saturate(0%);
    cursor: pointer;
}

.nav_welten img.active {
    filter: saturate(100%);
    scale: 1.1;
}

.score_welten {
    width: 100%;
}

.score_welten div.score_wrapper {
    float: left;
    width: 33.33%;
    margin-top: -40px;
    display: flex;
    justify-content: center;
}

.score_wrapper div {
    height: 10%;
    min-height: 50px;
    border-radius: 20px;
    padding-top: 20px;
    width: 60%;
}

.score_schutzrechte,
.score_foerderrechte,
.score_beteiligungsrechte {
    /*background-image: linear-gradient(to bottom, #666666, #343434);
    border-radius: 20px;
    border: 2px solid #454545;*/
    scale: 0.7;
    color: white;
    cursor: pointer;
}

.score_schutzrechte_active,
.score_foerderrechte_active,
.score_beteiligungsrechte_active {
    /*background-image: linear-gradient(to bottom, #fbb03b, #e1850c);
    border-radius: 20px;
    border: 2px solid #454545;
    color: white;*/
    scale: 1.1;
    cursor: pointer;
    /*transition: all 1s ease;*/
}

.score_schutzrechte div,
.score_foerderrechte div,
.score_beteiligungsrechte div,
.score_schutzrechte_active div,
.score_foerderrechte_active div,
.score_beteiligungsrechte_active div {
    width: 100%;
    padding: 0;
}

.stars img {

    height: 3vh;
}

.foerderrechte,
.beteiligungsrechte,
.schutzrechte {
    cursor: pointer;
    width: 60%;
}

/* animations */
#schutzrechte_img,
#score_schutzrechte {
    animation: grow_schutzrechte 0.5s 1;
}

#foerderrechte_img,
#score_foerderrechte {
    animation: grow_foerderrechte 1s;
}

#beteiligungsrechte_img,
#score_beteiligungsrechte {
    animation: grow_beteiligungsrechte 1.5s;
}

@keyframes grow_schutzrechte {
    0% {
        transform: scale(0);
    }

    95% {
        transform: scale(1.2)
    }

    100% {
        transform: scale(1);
    }
}

@keyframes grow_foerderrechte {
    0% {
        transform: scale(0);
    }

    50% {
        transform: scale(0);
    }

    95% {
        transform: scale(1.2)
    }

    100% {
        transform: scale(1);
    }
}

@keyframes grow_beteiligungsrechte {
    0% {
        transform: scale(0);
    }

    66% {
        transform: scale(0);
    }

    95% {
        transform: scale(1.2)
    }

    100% {
        transform: scale(1);
    }
}

.welten_wrapper {
    animation: pulsing 2s 2s infinite linear;
}

@keyframes pulsing {
    0% {
        transform: scale(1)
    }

    33% {
        transform: scale(1.05)
    }

    66% {
        transform: scale(0.95)
    }

    100% {
        transform: scale(1)
    }
}

#avatar_big, #share_link {
    animation-name: avatar_fall, float;
    animation-duration: 2s, 2s;
    animation-delay: 0s, 2s;
    animation-iteration-count: 1, infinite;
    animation-timing-function: linear, linear;
}

@keyframes avatar_fall {
    0% {
        transform: translateY(-60vh);
    }

    75% {
        transform: translateY(-60vh);
    }

    90% {
        transform: translateY(+5vh)
    }

    95% {
        transform: translateY(-2vh)
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes float {
    0% {
        transform: translatey(0);
    }

    33% {
        transform: translatey(-1vh);
    }

    66% {
        transform: translatey(1vh);
    }

    100% {
        transform: translatey(0vh);
    }
}

.star_voll {
    border-radius: 50%;
    animation: star_voll 2s 2s 2 alternate;
}

@keyframes star_voll {
    from {
        filter: hue-rotate(0);
        transform: scale(1);
    }

    to {
        filter: hue-rotate(360deg);
        transform: scale(1.4);
    }
}