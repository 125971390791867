.content-placer .active {
    filter:opacity(100);
    border: 3px solid #ffb11f;
    border-radius: 50%;
    animation: glow 1s 2s 2 alternate;
}
.content-placer .active:hover {
    cursor: pointer;
}
.content-placer .done {
    filter:opacity(50%);
}

/* animations */
#content_1 {
    animation: bubble_1 0.25s;
}
#content_2 {
    animation: bubble_2 0.5s;
}
#content_3 {
    animation: bubble_3 0.75s;
}
#content_4 {
    animation: bubble_4 1s;
}
#content_5 {
    animation: bubble_5 1.25s;
}
#content_6 {
    animation: bubble_6 1.5s;
}
#content_7 {
    animation: bubble_7 1.75s;
}
#content_8 {
    animation: bubble_8 2s;
}
#content_9 {
    animation: bubble_9 2.25s;
}
#content_10 {
    animation: bubble_10 2.5s;
}

@keyframes bubble_1 {
    0% {transform: scale(0)}
    90% {transform: scale(1.2)}
    100% {transform: scale(1)}
}
@keyframes bubble_2 {
    0% {transform: scale(0)}
    50% {transform: scale(0)}
    95% {transform: scale(1.2)}
    100% {transform: scale(1)}
}
@keyframes bubble_3 {
    0% {transform: scale(0)}
    66% {transform: scale(0)}
    96% {transform: scale(1.2)}
    100% {transform: scale(1)}
}
@keyframes bubble_4 {
    0% {transform: scale(0)}
    75% {transform: scale(0)}
    97% {transform: scale(1.2)}
    100% {transform: scale(1)}
}
@keyframes bubble_5 {
    0% {transform: scale(0)}
    80% {transform: scale(0)}
    98% {transform: scale(1.2)}
    100% {transform: scale(1)}
}
@keyframes bubble_6 {
    0% {transform: scale(0)}
    83% {transform: scale(0)}
    98% {transform: scale(1.2)}
    100% {transform: scale(1)}
}
@keyframes bubble_7 {
    0% {transform: scale(0)}
    86% {transform: scale(0)}
    98% {transform: scale(1.2)}
    100% {transform: scale(1)}
}
@keyframes bubble_8 {
    0% {transform: scale(0)}
    88% {transform: scale(0)}
    99% {transform: scale(1.2)}
    100% {transform: scale(1)}
}
@keyframes bubble_9 {
    0% {transform: scale(0)}
    89% {transform: scale(0)}
    99% {transform: scale(1.2)}
    100% {transform: scale(1)}
}
@keyframes bubble_10 {
    0% {transform: scale(0)}
    90% {transform: scale(0)}
    99% {transform: scale(1.2)}
    100% {transform: scale(1)}
}

/* walking avatar */
.walking_avatar_right img {
    height: 15vh;
    transform: scaleX(-1);
    
}
.is_walking_avatar_right img {
    height: 15vh;
    transform: scaleX(-1);
}
.is_walking_avatar_right {
    transition: all 1s ease;
}
.walking_avatar_left img {
    height: 15vh;
    transform: scaleX(1);
    
}
.is_walking_avatar_left img {
    height: 15vh;
    transform: scaleX(1);
}
.is_walking_avatar_left {
    transition: all 1s ease;
}