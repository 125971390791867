.schutzrechte_games_overview {
    position: absolute;
    width: 100%;
    margin: auto;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    z-index: 100;
    overflow: hidden;
    background-image: url('../../assets/bg/schutzrechte/Unterwasser_Welt.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    filter: blur(6px);
}
.foerderrechte_games_overview {
    position: absolute;
    width: 100%;
    margin: auto;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    z-index: 100;
    overflow: hidden;
    background-image: url('../../assets/bg/foerderrechte/Vulcan_Welt.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    filter: blur(6px);
}
.beteiligungsrechte_games_overview {
    position: absolute;
    width: 100%;
    margin: auto;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    z-index: 100;
    overflow: hidden;
    background-image: url('../../assets/bg/beteiligungsrechte/Weltall_Welt.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    filter: blur(6px);
}
.games_overview_wrapper {
    padding-top: 7vh;
    position: absolute;
    z-index: 100;
    width: 100%;
    height: 93vh;
}
.title_schutzrechte h2, .title_foerderrechte h2, .title_beteiligungsrechte h2 {
    color: white;
}
.games_overview_content {
    display: grid;
    grid-template-columns: 33vw 34vw 33vw;
}
.games_overview_schutzrechte {
    margin: 2vw;
    background-color: #DCE7EF;;
    border-radius: 3vh;
    overflow-y: auto;
    height: 60vh;
}
.games_overview_foerderrechte {
    margin: 2vw;
    background-color: #EDD7D6;
    border-radius: 3vh;
    overflow-y: auto;
    height: 60vh;
}
.games_overview_beteiligungsrechte {
    margin: 2vw;
    background-color: #F7DEF5;
    border-radius: 3vh;
    overflow-y: auto;
    height: 60vh;
}
.games_content {
    display: grid;
    grid-template-columns: 33% 34% 33%;
    padding: 0vh 1vw 0vh 1vw;
}
.games_content div {
    margin-bottom: 1vh;
    border-bottom: solid 1px;
}