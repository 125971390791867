.schutzrechte_content_games {
    position: absolute;
    width: 100%;
    margin: auto;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    z-index: 100;
    overflow: hidden;
    background-image: url('../../assets/bg/schutzrechte/Unterwasser_Welt.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    filter: blur(6px);
}

.foerderrechte_content_games {
    position: absolute;
    width: 100%;
    margin: auto;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    z-index: 100;
    overflow: hidden;
    background-image: url('../../assets/bg/foerderrechte/Vulcan_Welt.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    filter: blur(6px);
}

.beteiligungsrechte_content_games {
    position: absolute;
    width: 100%;
    margin: auto;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    z-index: 100;
    overflow: hidden;
    background-image: url('../../assets/bg/beteiligungsrechte/Weltall_Welt.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    filter: blur(6px);
}

#schutzrechte_popup {
    position: absolute;
    bottom: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/backgrounds/bg_blue.svg');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100% auto;
}

#foerderrechte_popup {
    position: absolute;
    z-index: 100;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/backgrounds/bg_red.svg');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100% auto;
}

#beteiligungsrechte_popup {
    position: absolute;
    z-index: 100;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/backgrounds/bg_violet.svg');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100% auto;
}

table {
    margin-left: auto;
    margin-right: auto;
}

.content_overview {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #fff;
    text-align: center;
}

.intro,
.outro {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
}

.content,
.page_2 {
    position: absolute;
    z-index: 2;
    width: 60vw;
    height: 90vh;
    margin-left: 20vw;
    margin-top: 5vh;
    border-radius: 2vh;
    overflow-y: auto;
    font-size: 1.2em;
}

#schutzrechte_popup .page_2,
#schutzrechte_popup .content {
    background-color: #DCE7EF;
}

#foerderrechte_popup .page_2,
#foerderrechte_popup .content {
    background-color: #EDD7D6;
}

#beteiligungsrechte_popup .page_2,
#beteiligungsrechte_popup .content {
    background-color: #F7DEF5;
}

.nav-bar-bottom {
    position: absolute;
    z-index: 1;
}

.inactive {
    display: none;
}

.intro-top {
    height: 40%;
    /*border: 1px solid red;*/
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.intro-message {
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    height: 20vh;
    font-size: 2em;
    animation: rollout 2s;
    /*background-color: #fdd79d;
    border: 3px solid #333333;
    border-radius: 2% 6% 5% 4%/1% 1% 2% 4%;*/
    padding: 2vh;
    border-radius: 5vh;
    border: 3px solid #333333;
    position: relative;
    background-color: #fafafa;
    /*background-color: rgb(0, 0, 0, 0.5);*/
    color: #333333;
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes rollout {
    0% {
        /*border: 0px solid white;
        background-color: white;*/
        width: 0px;
        /*height: 20%;*/
        visibility: hidden;
    }

    50% {
        /*border: 0px solid white;
        background-color: white;*/
        width: 0px;
        /*height: 20%;*/
        visibility: visible;
    }

    100% {
        /*background-color: #fdd79d;
        border: 3px solid #333333;*/
        width: 60%;
        /*height: 20%;*/
    }
}

/*.intro-message::before {
    background-color: #fdd79d;
    content: "";
    border: 2px solid #353535;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0) scale(1.015) rotate(0.5deg);
    border-radius: 1% 1% 2% 4%/2% 6% 5% 4%;
    animation: rollout_shadow 2s;
}*/

@keyframes rollout_shadow {
    0% {
        border: 0px solid white;
        background-color: white;
        width: 0px;
        height: 100%;
    }

    50% {
        border: 0px solid white;
        background-color: white;
        width: 0px;
        height: 100%;
    }

    100% {
        background-color: #fdd79d;
        border: 3px solid #333333;
        width: 100%;
        height: 100%;
    }
}

.message-text {
    animation: animate_text 2.1s;
    height: 20vh;
    overflow-y: auto;
}

@keyframes animate_text {
    0% {
        opacity: 0;
    }

    99% {
        opacity: 0
    }

    100% {
        opacity: 100
    }
}

.speaker-button {
    position: absolute;
    bottom: 1vh;
    right: 1vw;
}
.speaker-button img {
    animation: animate_speaker-button 2.1s;
    width: 3vw;
    cursor: pointer;
}

@keyframes animate_speaker-button {
    0% {
        width: 0
    }

    99% {
        width: 0
    }

    100% {
        width: 3vw
    }
}

.intro-bottom {
    height: 60%;
    /*background-color: #44ffffff;*/
    display: flex;
    flex-direction: row;
}

.intro-bottom-left {
    width: 50%;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    animation: avatar_fall_games 1s;
}

@keyframes avatar_fall_games {
    0% {
        transform: translateY(-100vh);
    }

    90% {
        transform: translateY(+5vh)
    }

    95% {
        transform: translateY(-2vh)
    }

    100% {
        transform: translateY(0);
    }
}

.intro-bottom-left>.avatar_all {
    transform: scaleX(-1) translateX(35%);
}
.intro-bottom-left img {
    max-height: 40vh;
}

.intro-bottom-right {
    width: 50%;
    /*border: 1px solid green;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.intro-bottom-right>img {
    margin-bottom: 15vh;
    max-height: 40vh;
}


.intro_left {
    width: 50%;
    height: 100%;
    float: left;
}

.intro_left_top {
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.intro_left_top img {
    height: 20vh;
}

.intro_left_bottom {
    position: absolute;
    z-index: 1;
    width: 50%;
    height: 50%;
    display: flex;
    justify-content: right;
    align-items: right;
    transform: scaleX(-1);
}

.intro_left_bottom img {
    height: 40vh;
}

.intro_left_bottom .avatar_icon,
.intro_left_bottom .avatar_all {
    height: 40vh;
    width: 10vw;
}

.intro_right {
    width: 50%;
    height: 100%;
    float: right;
    overflow-y: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.intro_right img {
    height: 25px;
    cursor: pointer;

}

.content_text {
    width: 100%;
    background-color: rgb(113, 123, 254);
    border-radius: 10px;
    margin-right: 10px;
}

.close {
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 4px;
    margin-left: 30px;
    z-index: 100;
}

.close img {
    height: 40px;
    cursor: pointer;
}

.forward {
    visibility: hidden;
}

.forward img,
.forward_active img {
    height: 40px;
    cursor: pointer;
}

.forward_active {
    position: absolute;
    margin-bottom: 4px;
    margin-right: 30px;
    bottom: 0;
    right: 0;
    visibility: visible;
    z-index: 100;
}

.backbutton {
    visibility: hidden;
}

.backbutton img,
.backbutton_active img {
    height: 40px;
    cursor: pointer;
}

.backbutton_active {
    position: absolute;
    margin-bottom: 4px;
    margin-left: 30px;
    float: left;
    bottom: 0;
    left: 0;
    visibility: visible;
    z-index: 100;
}

.finish {
    visibility: hidden;
}

.finish img,
.finish_active img {
    height: 40px;
    cursor: pointer;
}

.finish_active {
    position: absolute;
    margin-bottom: 4px;
    margin-right: 30px;
    bottom: 0;
    right: 0;
    visibility: visible;
    z-index: 100;
}

.timer_bottom {
    width: 100%;
    height: 11vh;
    min-height: 40px;
    position: fixed;
    z-index: 100;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    bottom: 0;
    margin-bottom: 2vh;
}

.timer_left {
    margin: 10px 0px 10px 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 1vw;
    flex-basis: 35%;
}

.timer_center {
    margin: 10px 0px 10px 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-basis: 30%;
}

.timer_right {
    margin: 10px 40px 10px 0px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 1vw;
    flex-basis: 35%;
}

/* just-another-hand-regular - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Just Another Hand';
    font-style: normal;
    font-weight: 400;
    src: url('../../assets/fonts/just-another-hand-v19-latin-regular.eot'); /* IE9 Compat Modes */
    src: url('../../assets/fonts/just-another-hand-v19-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../assets/fonts/just-another-hand-v19-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../assets/fonts/just-another-hand-v19-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../../assets/fonts/just-another-hand-v19-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../assets/fonts/just-another-hand-v19-latin-regular.svg#JustAnotherHand') format('svg'); /* Legacy iOS */
  }

.timer_bubble {
    height: 8vh;
    width: 8vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: large;
    color: #fff;
    font-weight: bold;
    font-family: "Just Another Hand";
    position: absolute;
    z-index: 2;
}

.timer_bg img {
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    min-height: 40px;
    transition: all 0.1s ease-out;
}

.quiz {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.outer_content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.inner_content {
    /*margin-top: 10vh;*/
    width: 80vw;
    /*background-color: white;*/
}