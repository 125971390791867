.wimmelbild .image-mapper a {
    cursor: pointer;
}
.wimmelbild .image-mapper #active {
    fill: green;
    opacity: 0.5;
}
.wimmelbild .image-mapper #wrong {
    fill: red;
    opacity: 0.5;
}
.wimmelbild .image-mapper rect, .wimmelbild .image-mapper circle, .wimmelbild .image-mapper polygon {
    fill: #fff;
    opacity: 0;
}
.wimmelbild .image-mapper rect:hover, .wimmelbild .image-mapper circle:hover, .wimmelbild .image-mapper polygon:hover {
    fill: rgb(241, 171, 171);
    opacity: 0.0;
}
.wrapper_wimmelbild {
    display: flex;
    justify-content: center;
    align-items: center;
}
.wimmelbild_message {
    width: 20vw;
    height: 10vh;
    background-color: rgb(156, 221, 156);
    position: absolute;
    top: 40vh;
    left: 40vw;
    z-index: 100;
    display: none;
    border-radius: 15px;
    align-items: center;
    justify-content: center;
}