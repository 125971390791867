.sketchy {
    padding: 1rem 1rem;
    border: 3px solid #333333;
    border-radius: 2% 6% 5% 4%/1% 1% 2% 4%;
    background-color: #fdd79d;
    position: relative;
  }
  .sketchy::before {
    background-color: #fdd79d;
    content: "";
    border: 2px solid #353535;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0) scale(1.015) rotate(0.5deg);
    border-radius: 1% 1% 2% 4%/2% 6% 5% 4%;
  }