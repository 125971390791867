.avatar_all {
    height: 100%;
    width: 100%;
}
.avatar_icon {
    position: relative;
    display: contents;
}
.skin_home {
    top: 0;
    position: absolute;
    z-index: 2;
}
.clothes_top_home {
    top: 0;
    position: absolute;
    z-index: 5;
}
.clothes_bottom_home {
    top: 0;
    position: absolute;
    z-index: 4;
}
.face_home {
    top: 0;
    position: absolute;
    z-index: 3;
}
.hair_home {
    top: 0;
    position: absolute;
    z-index: 6;
}
.shoe_home {
    top: 0;
    position: absolute;
    z-index: 3;
}
.accessoire_home {
    top: 0;
    position: absolute;
    z-index: 8;
}
.surprise_home {
    top: 0;
    position: absolute;
    z-index: 9;
}
.avatar_shadow {
    top: 0;
    position: absolute;
    z-index: 1;
}
.avatar_badge1 {
    top: 0;
    position: absolute;
    z-index: 7;
}