.outer_content_invisible {
    display: none;
}
.outer_content_visible {
    margin-top: 10vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.wrapper_einfache_sprache {
    padding: 5vh 5vw 5vh 5vw;
}
.einfache_sprache {
    width: 80%;
    background-color: rgb(218, 254, 218);
    margin: auto;
    border-radius: 2vh;
}
.komplizierte_sprache {
    width: 80%;
    background-color: rgb(252, 190, 190);
    margin: auto;
    border-radius: 2vh;
}