/* just-another-hand-regular - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Just Another Hand';
    font-style: normal;
    font-weight: 400;
    src: url('../../assets/fonts/just-another-hand-v19-latin-regular.eot'); /* IE9 Compat Modes */
    src: url('../../assets/fonts/just-another-hand-v19-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../assets/fonts/just-another-hand-v19-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../assets/fonts/just-another-hand-v19-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../../assets/fonts/just-another-hand-v19-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../assets/fonts/just-another-hand-v19-latin-regular.svg#JustAnotherHand') format('svg'); /* Legacy iOS */
  }

body {
    overflow-x: hidden;
}

.welt_wrapper {
    position: absolute;
    z-index: -200;
    height: 100vh;
    width: 100vw;
    background-image: url('../../assets/backgrounds/spacebg.jpg');
    background-size: cover;
}

.background-image {
    max-height: 100%;
    min-height: 100%;
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -100;
}

.background-image .image img {
    height: 100vh;
}

.background-image .content {
    z-index: 10;
    position: absolute;
    top: 70%;
    right: 15%;
}

.background-image .content img {
    height: 10vh;
}

.background-sound {
    visibility: hidden;
}

.image-mapper-canvas {
    height: inherit;
}

.notification_wrapper {
    position: absolute;
    z-index: 200;
    height: 100%;
    width: 100%;
    background-color: rgb(256, 256, 256, 0.5);
}

.notification {
    position: relative;
    background-color: azure;
    margin: auto;
    width: 80vh;
    border-radius: 20px;
    min-height: 30vh;
    margin-top: 30vh;
    animation-duration: 2s;
    animation-direction: normal;
    border: 2px solid #444444;
}

.notification .notification_close {
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 10px;
    margin-left: 10px;
    z-index: 100;
}

.notification .link_to_avatar {
    text-decoration: underline;
    cursor: pointer;
}

.notification .notification_close img {
    height: 8vh;
    min-height: 20px;
    cursor: pointer;
}

.notification .notification_content {
    font-family: 'Just Another Hand';
    font-size: 1.5em;
    letter-spacing: 0.1em;
    padding-top: 10vh;
}


#img_wrapper {
    height: 20vh;
    width: 20vh;
    position: relative;
    margin: 0 auto;
}

.notification_content .badge_img {
    height: 10vh;
    position: absolute;
    z-index: 2;
    top: 5vh;
    left: 5vh;
}

#rays {
    width: 20vh;
    height: 20vh;
    position: absolute;
    z-index: 1;
    background: url(../../assets/backgrounds/badge_rays.png) 0 0 no-repeat;
    background-size: contain;

    animation-name: spin;
    animation-duration: 20000ms;
    /* 40 seconds */
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

/*spinning wheel */
@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.temporary_arrow {
    position: absolute;
    right: 0;
    top: 42vh;
    animation: disapear 3s;
    animation-delay: 3s;
    visibility: hidden;
}

.temporary_arrow img {
    height: 15vh;
    transform: scaleX(-1);
}

/* animation */
@keyframes disapear {
    0% {
        visibility: visible;
        transform: scale(0.8);
    }

    15% {
        transform: scale(1.2)
    }

    30% {
        transform: scale(0.8)
    }

    45% {
        transform: scale(1.2)
    }

    60% {
        transform: scale(0.8)
    }

    75% {
        transform: scale(1.2)
    }

    90% {
        transform: scale(0.8)
    }

    100% {
        transform: scale(0)
    }
}

.btn_to_avatar {
    height: 8vh;
}