.qr_code {
    height: auto;
    margin: 0 auto;
    
    width: 15vw;
}
.qr_code_generator {
    padding-top:15vh;
    height: 85vh;
    width: 100vw;
    background-image: url('../../assets/backgrounds/spacebg.jpg');
    background-size:cover;
}
.qr_wrapper {
    width: 50vw;
    height: 60vh;
    margin: 0 auto;
    background-color: rgb(0,0,0,0.5);
    border-radius: 3vh;
    padding: 1vh 1vw 1vh 1vw;
}