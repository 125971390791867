/* just-another-hand-regular - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Just Another Hand';
    font-style: normal;
    font-weight: 400;
    src: url('../../assets/fonts/just-another-hand-v19-latin-regular.eot'); /* IE9 Compat Modes */
    src: url('../../assets/fonts/just-another-hand-v19-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../assets/fonts/just-another-hand-v19-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../assets/fonts/just-another-hand-v19-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../../assets/fonts/just-another-hand-v19-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../assets/fonts/just-another-hand-v19-latin-regular.svg#JustAnotherHand') format('svg'); /* Legacy iOS */
  }

.start-page {
    width: 100vw;
    height: 100vh;
    background-image: url('../../assets/backgrounds/spacebg.jpg');
    background-size: cover;
}

.start-content {
    position: absolute;
    bottom: 5vh;
    right: 15vw;
    z-index: 2;
}

.start-content h2 {
    color: white;
}

.logo {
    position: absolute;
    top: 7vh;
    left: 3vw;
}

.logo img {
    height: 25vh;
}

.slogan {
    color: white;
    font-size: 2em;
    font-family: Arial;
    font-weight: bold;
    text-align: center;
    margin-top: -0.2em;
}

.info-link {
    position: absolute;
    top: 5vh;
    right: 5vw;
}

.info-link img {
    height: 10vh;
}

.forward-button {
    position: absolute;
    right: 5vw;
    bottom: 5vh;
    z-index: 2;
}
.forward-button img {
    height: 10vh;
}

.backward-button {
    position: absolute;
    left: 5vw;
    bottom: 5vh;
    z-index: 2;
}
.backward-button img {
    height: 10vh;
}

.start-avatar-wrapper {
    position: absolute;
    height: 65vh;
    bottom: 15vh;
    right: 30vw;
    z-index: 2;
}

.start-avatar {
    height: 65vh;
    position: absolute;
}

.start-avatar img {
    height: 65vh;
}

.start-language {
    position: absolute;
    top: 55vh;
    width: 100vw;
    display: grid;
    grid-template-columns: auto 25vh 25vh 25vh auto;
}

[data-lng] {
    cursor: pointer;
}

.start-language img {
    width: 15vh;
}

.start-language .active {
    border: 3px solid #bfbfbf;
    background-image: linear-gradient(to bottom, #fbb03b, #e1850c);
    border-radius: 10%;
    border-color: white;
}

.start-language .active img {
    border: 3px solid white;
    border-radius: 100%
}

.start-language .passive img {
    border: 3px solid black;
    border-radius: 100%
}

.start-language .passive {
    border: 3px solid rgb(0, 0, 0, 0.5);
    background-color: rgb(0, 0, 0, 0.5);
    border-radius: 10%;
}

#lng-select {
    width: 22vh;
    height: 24vh;
    padding-top: 2vh;
}

#lng-select p {
    color: white;
    font-family: Arial;
    font-size: 2em;
    margin-top: 0.3em;
}

.bg-content {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100vw;
    height: 40%;
    z-index: 1;
    background-image: url('../../assets/backgrounds/bg.svg');
    background-repeat: no-repeat;
    /*background-attachment: fixed;*/
    /*background-position: center;*/
    background-size: cover;
    overflow-y: hidden;
}

.start-intro {
    position: absolute;
    background-color: rgb(0, 0, 0, 0.5);
    border-radius: 5vh 5vh 5vh 5vh;
    bottom: 8vh;
    left: 15vw;
    width: 70vw;
    /*height: 40vh;*/
    overflow-y: auto;
    overflow-x: hidden;
    animation: bounce-in 1s ease 1;
}

@keyframes bounce-in {
    0% {
        opacity: 0;
        transform: scale(.3);
    }

    50% {
        opacity: 1;
        transform: scale(1.05);
    }

    70% {
        transform: scale(.9);
    }

    100% {
        transform: scale(1);
    }
}

.start-intro-header {
    font-family: 'Just Another Hand';
    text-transform: uppercase;
    letter-spacing: 0.1em;
    color: #ffb11f;
    font-size: 2em;
}

.start-intro-text {
    color: white;
    font-size: 2em;
}

.info-link,
.next-button {
    cursor: pointer;
    transition: all 0.1s ease-out;
}

.info-link:hover,
.next-button:hover {
    transform: scale(1.2);
}

.speaker-start-button {
    position: absolute;
    top: 1vh;
    right: 1vw;
}
.speaker-start-button img {
    animation: animate_speaker-button 2.1s;
    width: 3vw;
    cursor: pointer;
}