/*body {
  background-color: black;
}*/

.App {
  width: 100%;
  height: 100%;
  text-align: center;
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;
}

.fontSmall {
  font-size: 1.5vh;
}

.fontMiddle {
  font-size: 2vh;
}

.fontBig {
  font-size: 2.5vh;
}

#pwdDesc {
  font-size: small;
  color: gray;
}

@media screen and (orientation: portrait) {
  .info_portrait {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-image: url('/src/assets/backgrounds/spacebg.jpg');
    background-size: cover;
    text-align: center;
  }
  .info_portrait img {
    height: 50vh;
  }
  .App {
    display: none;
  }
  /*html {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 100%;
    left: 0;
  }*/
}

@media screen and (orientation: landscape) {
  .info_portrait {
    display: none;
  }
}

/* SCROLLBAR */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.no-visual-events {
  pointer-events: none;
}
