/* just-another-hand-regular - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Just Another Hand';
    font-style: normal;
    font-weight: 400;
    src: url('../../assets/fonts/just-another-hand-v19-latin-regular.eot'); /* IE9 Compat Modes */
    src: url('../../assets/fonts/just-another-hand-v19-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../assets/fonts/just-another-hand-v19-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../assets/fonts/just-another-hand-v19-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../../assets/fonts/just-another-hand-v19-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../assets/fonts/just-another-hand-v19-latin-regular.svg#JustAnotherHand') format('svg'); /* Legacy iOS */
  }

.settings_wrapper {
    padding-top: 15vh;
    height: 85vh;
    width: 100vw;
    background-image: url('../../assets/backgrounds/spacebg.jpg');
    background-size: cover;
}

.settings_content {
    padding: 1vh 3vw 5vh 3vw;
    color: white;
    width: 60vw;
    margin: 0 auto;
    background-color: rgb(0, 0, 0, 0.5);
    border-radius: 5vh 0vh 0vh 5vh;
    overflow-y: scroll;
    height: 70vh;
}

.settings_selections {
    display: grid;
    grid-template-columns: 20vw 20vw 20vw;
    margin-bottom: 2vh;
}

fieldset {
    margin-bottom: 3vh;
    border-radius: 2vh;
    border-color: #e1850c;
    height: 80%;
}

button {
    border-radius: 1vh;
    border: 2px solid grey;
    padding: 2vh;
    background-image: linear-gradient(to bottom, #fbb03b, #e1850c);
    cursor: pointer;
    color: white;
    font-size: calc(12px + 1vh);
}

legend {
    background-image: linear-gradient(to bottom, #fbb03b, #e1850c);
    height: 5vh;
    border-radius: 1vh;
    margin-top: 1.5em;
    font-size: 1em;
    font-family: 'Just Another Hand';
    text-transform: uppercase;
    letter-spacing: 0.1em;
    padding: 2vh 1vw 0vh 1vw;
}

.qr_code {
    background-image: linear-gradient(to bottom, #fbb03b, #e1850c);
    padding: 1vh 1vw 1vh 1vw;
    border-radius: 1vh;
    color: black;
    text-decoration: none;
}