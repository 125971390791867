/* silkscreen-regular - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Silkscreen';
    font-style: normal;
    font-weight: 400;
    src: url('../../assets/fonts/silkscreen-v1-latin-regular.eot'); /* IE9 Compat Modes */
    src: url('../../assets/fonts/silkscreen-v1-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../assets/fonts/silkscreen-v1-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../assets/fonts/silkscreen-v1-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../../assets/fonts/silkscreen-v1-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../assets/fonts/silkscreen-v1-latin-regular.svg#Silkscreen') format('svg'); /* Legacy iOS */
  }

.nav-bar {
    /*border: 1px solid red;*/
    width: 100%;
    height: 11vh;
    min-height: 60px;
    max-height: 200px;
    position: fixed;
    z-index: 100;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    top: 2vh;
}

.nav-bar-bottom {
    /*border: 1px solid red;*/
    width: 100%;
    height: 11vh;
    min-height: 60px;
    max-height: 200px;
    position: fixed;
    z-index: 100;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    bottom: 0;
    margin-bottom: 2vh;
}

.nav-left {
    margin: 10px 0px 10px 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 1vw;
    flex-basis: 30%;
}

.nav-center {
    margin: 10px 0px 10px 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-basis: 27%;
}

.nav-right {
    margin: 10px 10px 10px 0px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 1vw;
    flex-basis: 35%;
}
.help_navbar {
    margin-top: 10px;
    margin-bottom: 10px;
    flex-basis: 8%;
}

.btn {
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    transition: all 0.1s ease-out;
    cursor: pointer;
}

.welcome {
    color: #ffffff;
    font-size: 3.5vh;
    font-weight: bold;
}

.btn:hover {
    transform: scale(1.2);
}

#help {
    border-radius: 50%;
    background-color: #ccc;
    animation: glow 1s infinite alternate;
}

#hair_img {
    cursor: pointer;
}

@keyframes glow {
    from {
        box-shadow: 0 0 10px -10px #ffb11f;
    }

    to {
        box-shadow: 0 0 10px 10px #ffb11f;
    }
}

.score {
    font-family: 'Silkscreen';
    font-weight: bold;
    width: 18vw;
    min-width: 150px;
    padding: 15px;
    color: white;
    background-image: linear-gradient(to bottom, #fbb03b, #e1850c);
    border-radius: 10px;
    border: 2px solid #454545;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 3vh;
}

.score.counting {
    animation-name: grow;
    animation-duration: 4s;
    animation-iteration-count: 1;
}

@keyframes grow {
    0% {
        transform: scale(1) translate(0, 0);
    }

    10% {
        transform: scale(2) translate(-20%, 40%);
    }

    100% {
        transform: scale(1) translate(0, 0);
    }
}

.score>.label {}

.score>.points {}

.nav-avatar {
    background-image: linear-gradient(to bottom, #fbb03b, #e1850c);
    height: calc(100%-4px);
    max-width: calc(100%-4px);
    max-height: calc(100%-4px);
    aspect-ratio: 1/1;
    border-radius: 50%;
    border: 2px solid #454545;
    position: relative;
}

.nav-avatar>img {
    width: 100%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    cursor: pointer;
}

.navbar_logo {
    height: 6vh;
}

.upper_name {
    text-transform: uppercase;
}