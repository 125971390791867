/* silkscreen-regular - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Silkscreen';
    font-style: normal;
    font-weight: 400;
    src: url('../../assets/fonts/silkscreen-v1-latin-regular.eot'); /* IE9 Compat Modes */
    src: url('../../assets/fonts/silkscreen-v1-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../assets/fonts/silkscreen-v1-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../assets/fonts/silkscreen-v1-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../../assets/fonts/silkscreen-v1-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../assets/fonts/silkscreen-v1-latin-regular.svg#Silkscreen') format('svg'); /* Legacy iOS */
  }
/* just-another-hand-regular - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Just Another Hand';
    font-style: normal;
    font-weight: 400;
    src: url('../../assets/fonts/just-another-hand-v19-latin-regular.eot'); /* IE9 Compat Modes */
    src: url('../../assets/fonts/just-another-hand-v19-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../assets/fonts/just-another-hand-v19-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../assets/fonts/just-another-hand-v19-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../../assets/fonts/just-another-hand-v19-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../assets/fonts/just-another-hand-v19-latin-regular.svg#JustAnotherHand') format('svg'); /* Legacy iOS */
  }

.share-score-wrapper {
    padding-top:15vh;
    height: 85vh;
    width: 100vw;
    background-image: url('../../assets/backgrounds/spacebg.jpg');
    background-size:cover;
}
.share-content-wrapper {
    width: 50vw;
    height: 65vh;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 0 auto;
}
.share_content {
    width: 100%;
    height: 100%;
    text-align: center;
    position: relative;
    background-color: rgb(0,0,0,0.5);
    border-radius: 3vh;

}
.logo_div {
    position: absolute;
    top: 3vh;
    left: 2vw;
}
.logo_div .logo_img {
    height: 10vh;
}
.share_content_screenshot {
    width: 100vw;
    height: 100vh;
    text-align: center;
    position: relative;
    background-color: rgb(0,0,0,0.5);
    border-radius: 3vh;
}
.share_avatar {
    position: relative;
    margin: 0 auto;
    height: 50vh;
    width: 50vw;
    transform: translate(35%);
}
.share_content h2 {
    padding-top: 1vh;
    margin-bottom: -3vh;
}

head {
    z-index:10;
}

.share_content img, .share_content_screenshot img {
    height: 50vh;
}

.error_hidden {
    display: none;
}
.error_show {
    display:block;
    margin: 0 auto;
    width: 50vw;
    height: 50vh;
    background-color: rgb(0,0,0,0.5);
    border-radius: 3vh;
    color: white;
}
#error {
    width: 50vw;
}
.share_points {
    background-image: linear-gradient(to bottom, #fbb03b, #e1850c);
    width: 15vw;
    height: 5vh;
    margin: 0 auto;
    border-radius: 1vh;
    color: white;
    font-family: 'Silkscreen';
    font-size: 3vh;
    justify-content: space-between;
    align-items: center;
    display: flex;
    flex-direction: row;
    padding: 0vh 1vw 0vh 1vw;
    margin-bottom: 1vh;
}
.share_content_title {
    color: white;
    padding-top: 2vh;
    font-family: 'Just Another Hand';
    font-size: 2.5em;
    margin-bottom: -2vh;
}